<template>
  <v-container>
    <v-layout justify-center align-center>
      <v-flex>
        <v-data-table
          dense
          :headers="headers"
          :items="list"
          :items-per-page="itemsPerPage"
          :loading="isLoading"
          loading-text="CARREGANDO..."
        >
          <template v-slot:top>
            <v-toolbar>
              <v-flex>
                <v-row>
                  <v-col cols="12" sm="4" md="6">
                    <v-text-field
                      prepend-icon="mdi-magnify"
                      v-model="searchText"
                      label="Nome"
                      @keyup.enter="search"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      prepend-icon="mdi-magnify"
                      label="CPF"
                      v-mask="'###.###.###-##'"
                      v-model="$v.searchCpf.$model"
                      :error-messages="searchCpfError"
                      @keyup.enter="search"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1">
                    <v-btn small fab @click="search">
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-flex>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    Criar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field
                            name="name"
                            label="Nome"
                            type="text"
                            :error-messages="nameErrors"
                            v-model.trim="$v.editedItem.name.$model"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            name="cpf"
                            label="CPF"
                            type="text"
                            v-mask="'###.###.###-##'"
                            :error-messages="cpfErrors"
                            v-model.trim="$v.editedItem.cpf.$model"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-for="(media, index) in socialMedia"
                          :key="index"
                        >
                          <v-text-field
                            :name="media.toLowerCase()"
                            :label="media"
                            type="text"
                            :error-messages="
                              socialMediaErrors(media.toLowerCase())
                            "
                            v-model.trim="
                              $v.editedItem[media.toLowerCase()].$model
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="$v.$invalid"
                      color="primary"
                      text
                      @click="save"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
          </template>
        </v-data-table>
        <v-snackbar
          :color="snackBar.color"
          v-model="snackBar.show"
          height="200"
          width="200"
          :multi-line="true"
          centered
          >{{ snackBar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackBar.show = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import api from '../../../plugins/api';

const validateHttps = (value) => value === 'NA' || value.includes('https://');
const validateUrl = (param) => (value) => {
  const validator = param === 'linkedin' ? `${param}.com/in/` : `${param}.com/`;

  return value === 'NA' || value.includes(validator);
};

export default {
  data: () => ({
    snackBar: {
      show: false,
      color: 'success',
      text: 'Atualização realizada com sucesso!'
    },
    dialog: false,
    dialogPreview: false,
    itemsPerPage: 15,
    socialMedia: ['Twitter', 'Facebook', 'Instagram', 'Linkedin', 'Youtube', 'Site'],
    headers: [
      { text: 'Nome', value: 'name', width: '400' },
      { text: 'CPF', value: 'cpf' },
      { text: 'Atualização', value: 'updated_at' },
      { text: 'Editar', value: 'edit', sortable: false }
    ],
    list: [],
    isLoading: false,
    searchText: '',
    searchCpf: '',
    select: '',
    selectFields: ['Nome', 'CPF'],
    editedIndex: -1,
    editedItem: {
      name: '',
      cpf: '',
      twitter: '',
      facebook: '',
      instagram: '',
      linkedin: '',
      youtube: '',
      site: '',
      updated_at: ''
    },
    defaultItem: {
      name: '',
      cpf: '',
      twitter: '',
      facebook: '',
      instagram: '',
      linkedin: '',
      youtube: '',
      site: '',
      updated_at: ''
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Criar' : 'Editar';
    },

    nameErrors() {
      const errors = [];
      const { name } = this.$v.editedItem;

      if (!name.$dirty) return errors;
      !name.required && errors.push('Insira o nome ou NA');
      !name.minlength && errors.push(`Insira no mínimo ${name.$params.minlength.min} caracteres!`);

      return errors;
    },

    cpfErrors() {
      const errors = [];
      const { cpf } = this.$v.editedItem;

      if (!cpf.$dirty) return errors;
      !cpf.required && errors.push('O CPF é obrigatório');
      !cpf.minlength && errors.push('CPF inválido!');

      return errors;
    },

    searchCpfError() {
      const errors = [];
      const cpf = this.$v.searchCpf;

      if (!cpf.$dirty) return errors;
      !cpf.minlength && errors.push('CPF inválido!');

      return errors;
    }
  },

  validations() {
    return {
      editedItem: {
        name: { required, minlength: minLength(2) },
        cpf: { required, minlength: minLength(14) },
        twitter: { required, validateHttps, validateUrl: validateUrl('twitter') },
        facebook: { required, validateHttps, validateUrl: validateUrl('facebook') },
        instagram: { required, validateHttps, validateUrl: validateUrl('instagram') },
        linkedin: { required, validateHttps, validateUrl: validateUrl('linkedin') },
        youtube: { required, validateHttps, validateUrl: validateUrl('youtube') },
        site: { required, validateHttps, validateUrl: validateUrl('site') }
      },
      searchCpf: { minlength: minLength(14) }
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.setTitle({ title: 'Pessoa Física' });
    this.initialize();
  },

  methods: {
    ...mapActions(['setTitle']),

    async initialize() {
      this.isLoading = true;
      this.list = await api.getPrivatePeople()
        .then((res) => res.data.map((el) => ({
          id: el.id,
          name: el.name,
          cpf: this.formatCpf(el.cpf),
          twitter: el.twitter,
          facebook: el.facebook,
          instagram: el.instagram,
          linkedin: el.linkedin,
          youtube: el.youtube,
          site: el.site,
          updated_at: new Date(el.updated_at).toLocaleDateString('pt-br'),
        })));

      this.isLoading = false;
    },

    async search() {
      await this.initialize();
      const searchText = this.searchText.trim().toLowerCase();

      this.list = this.list.filter((el) => {
        if (this.searchCpf.length === 14) {
          return el.cpf === this.searchCpf;
        }

        return el.name.toLowerCase().includes(searchText);
      });

      this.searchText = '';
      this.searchCpf = '';
    },

    socialMediaErrors(media) {
      const errors = [];
      const mediaName = this.$v.editedItem[media];

      if (!mediaName.$dirty) return errors;
      !mediaName.required && errors.push('Insira uma Url válida ou NA');
      !mediaName.validateHttps && errors.push('Url inválida!');
      !mediaName.validateUrl && errors.push(`Url inválida, a Url inserida não pertence ao ${media}`);

      return errors;
    },

    editItem(item) {
      this.editedIndex = this.list.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.update();
      } else {
        await this.create();
      }
    },

    formatCpf(cpf) {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },

    async create() {
      return api.createPrivatePeople(this.editedItem).then(async (res) => {
        const itemCreated = await { ...res.data };
        itemCreated.cpf = await this.formatCpf(res.data.cpf);
        itemCreated.updated_at = await new Date(itemCreated.updated_at).toLocaleDateString('pt-br');
        this.list.push(itemCreated);
        this.snackBar = { show: true, color: 'success', text: 'Atualização realizada com sucesso!' };
        this.close();
      }).catch(({ response }) => {
        const errors = response.data.map((item) => item.options.message);
        this.snackBar = { show: true, color: 'error', text: errors.join(' ') };
      });
    },

    async update() {
      return api.updatePrivatePeople(this.editedItem)
        .then(async (res) => {
          const itemUpdated = await { ...res.data };
          itemUpdated.cpf = await this.formatCpf(res.data.cpf);
          itemUpdated.updated_at = await new Date(itemUpdated.updated_at).toLocaleDateString('pt-br');
          Object.assign(this.list[this.editedIndex], itemUpdated);
          this.snackBar = { show: true, color: 'success', text: 'Atualização realizada com sucesso!' };
          this.close();
        }).catch(({ response }) => {
          const errors = response.data.map((item) => item.options.message);
          this.snackBar = { show: true, color: 'error', text: errors.join(' ') };
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    }
  },
};
</script>
